import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from '../../components/CaptionedImage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`After struggling for some time with `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/coreimage/cifilter"
      }}>{`CIFilter`}</a>{` documentation at work, I've been `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1080580341973348352"
      }}>{`working on an app`}</a>{` which can apply filters interactively for various inputs (another blog post to come on this later). As part of this app, I needed a UI for the user to select a color, and decided to implement a color wheel.`}</p>
    <p>{`There are several open source projects implementing color wheels on iOS, and StackOverflow has a `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/5108921/how-to-draw-a-color-wheel-in-objective-c"
      }}>{`few answers`}</a>{` which mostly recommend drawing the color wheel image yourself by iterating over the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/HSL_and_HSV"
      }}>{`Hue-Saturation-Lightness`}</a>{` color representation. However, I managed to find `}<a parentName="p" {...{
        "href": "http://www.chibicode.org/?p=62"
      }}>{`this post`}</a>{` about new CIFilters in iOS 10, which included a description of `}<inlineCode parentName="p">{`CIHueSaturationValueGradient`}</inlineCode>{`, and realized we can use this filter to generate a color wheel much more easily.`}</p>
    <h2 {...{
      "id": "coreimage-has-color-wheels-built-in"
    }}>{`CoreImage Has Color Wheels Built In`}</h2>
    <p>{`The description of `}<inlineCode parentName="p">{`CIHueSaturationValueGradient`}</inlineCode>{` is:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Generates a color wheel that shows hues and saturations for a specified value.`}</p>
    </blockquote>
    <p>{`For a given value of lightness (0 is dark, 1 is light), this filter's output image will be a wheel of all the hues and saturations for that lightness in a given color space. Code that looks like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` filter = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CIFilter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"CIHueSaturationValueGradient"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`parameters`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: [`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"inputColorSpace"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CGColorSpaceCreateDeviceRGB`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(),`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"inputDither"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"inputRadius"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`160`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"inputSoftness"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"inputValue"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`])!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` image = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIImage`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`ciImage`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: filter.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`outputImage`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`!)`}</span></span></code></pre>
    <p>{`Generates an output image that looks like this:`}</p>
    <CaptionedImage max="400" filename="cifilter-colorwheel-1.png" alt="Color wheel" caption="" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "details"
    }}>{`Details`}</h2>
    <p>{`The filter takes five parameters:`}</p>
    <p><inlineCode parentName="p">{`inputColorSpace`}</inlineCode>{`: This is a `}<inlineCode parentName="p">{`CGColorSpaceRef`}</inlineCode>{` representing the color space in which to generate the wheel. Generally, you're going to want to use `}<inlineCode parentName="p">{`CGColorSpaceCreateDeviceRGB()`}</inlineCode>{` for this, which will generate a wheel in the current device's color space.`}</p>
    <p><inlineCode parentName="p">{`inputDither`}</inlineCode>{`: The amount of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Dither"
      }}>{`dither`}</a>{` to apply to the wheel. I'm not exactly sure what this is useful for, but it can be fun to play around with. I also don't really know the units of this value, but I think it's pixels (if you can confirm, `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`let me know`}</a>{`). For example, here's a color wheel with `}<inlineCode parentName="p">{`inputDither`}</inlineCode>{` as 300:`}</p>
    <CaptionedImage max="400" filename="cifilter-colorwheel-2.png" alt="Color wheel with dither" caption="" mdxType="CaptionedImage" />
    <p><inlineCode parentName="p">{`inputRadius`}</inlineCode>{`: The value, `}<em parentName="p">{`in points`}</em>{`, of the radius of the wheel. A radius of 160 creates an image which is 320x320 points square (640x640 pixels on a device with 2x screen scale).`}</p>
    <p><inlineCode parentName="p">{`inputSoftness`}</inlineCode>{`: Specifies a softness value to smooth the gradient. A value of 0 means no smoothing, which is probably what you want for a color wheel. I'm also pretty sure the units here are pixels. Here's a color wheel with `}<inlineCode parentName="p">{`inputSoftness`}</inlineCode>{` as 4:`}</p>
    <CaptionedImage max="400" filename="cifilter-colorwheel-3.png" alt="Color wheel" caption="" mdxType="CaptionedImage" />
    <p><inlineCode parentName="p">{`inputValue`}</inlineCode>{`: The lightness value. 0 creates a black wheel, 1 means full lightness. Here's a color wheel with `}<inlineCode parentName="p">{`inputValue`}</inlineCode>{` as 0.5:`}</p>
    <CaptionedImage max="400" filename="cifilter-colorwheel-4.png" alt="Color wheel" caption="" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "getting-the-color-on-touch"
    }}>{`Getting The Color On Touch`}</h2>
    <p>{`I still needed to actually let the user select a color from the color wheel. Turns out there are tons of people online talking about how to get the color at a given touch location for a `}<inlineCode parentName="p">{`UIImageView`}</inlineCode>{`. I ended up going with `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/12770181/how-to-get-the-pixel-color-on-touch"
      }}>{`this one`}</a>{` which translated pretty well into an extension:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`extension`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`UIImageView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`getPixelColorAt`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`point`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: CGPoint) -> UIColor {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` pixel = `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`UnsafeMutablePointer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`CUnsignedChar`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`allocate`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`capacity`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`4`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` colorSpace = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CGColorSpaceCreateDeviceRGB`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` bitmapInfo = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CGBitmapInfo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rawValue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: CGImageAlphaInfo.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`premultipliedLast`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`rawValue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` context = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CGContext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: pixel,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`width`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`height`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bitsPerComponent`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`8`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bytesPerRow`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`4`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`space`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: colorSpace,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bitmapInfo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: bitmapInfo.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`rawValue`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        context!.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`translateBy`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: -point.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`y`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: -point.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`y`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        layer.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`render`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`in`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: context!)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` color = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`red`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CGFloat`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(pixel[`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]) / `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`255.0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`green`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CGFloat`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(pixel[`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]) / `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`255.0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`blue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CGFloat`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(pixel[`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]) / `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`255.0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`alpha`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CGFloat`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(pixel[`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`3`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]) / `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`255.0`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        pixel.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`deallocate`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` color`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <h2 {...{
      "id": "end-result"
    }}>{`End Result`}</h2>
    <p>{`It ended up `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1087511576871981056"
      }}>{`working pretty well`}</a>{`! Adding a gesture recognizer to the imageView which calls `}<inlineCode parentName="p">{`getPixelColorAt(point:)`}</inlineCode>{` allows us to easily determine the color that the user picked and render a simple UI to show the selected color:`}</p>
    <CaptionedImage max="400" filename="cifilter-colorwheel-5.png" alt="Color wheel" caption="" mdxType="CaptionedImage" />
    <p>{`All in all, this was a great exercise in figuring out the least complicated way to implement a complicated UI component, and I continue to learn about all the awesome stuff that CoreImage comes with out of the box (not just color wheels, but photo effects, blurs, distortions, etc).`}</p>
    <p>{`More on CIFilters soon to come! 👋`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      